import React from "react";

function JuridischeKennisgeving() {
  // Haal het huidige jaar op
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <main>
        <div className="body2">
          <div className="spacing3"></div>
          <div className="spacing3"></div>

          <p className="c1">
            <span className="c0" id="top">
              raimonvibe
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Laatst bijgewerkt: januari 2023</span>
          </p>
          <p className="c1">
            <span className="c0">
              Welkom bij raimonvibe. Door onze website te gebruiken, ga je akkoord met de
              voorwaarden die in deze juridische kennisgeving zijn uiteengezet.
            </span>
          </p>

          <p className="c1">
            <span className="c0">
              Lees de juridische kennisgeving zorgvuldig door voordat je onze website gebruikt.
              Als je vragen hebt, neem dan contact met ons op.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Juridische Kennisgeving</span>
          </p>
          <p className="c1">
            <span className="c0">
              De informatie op deze website is bedoeld voor algemene
              informatiedoeleinden. Hoewel we ons inspannen om ervoor te zorgen dat de
              informatie op deze website nauwkeurig en actueel is, geven we geen
              verklaringen of garanties van welke aard dan ook, expliciet of impliciet,
              over de volledigheid, juistheid, betrouwbaarheid, geschiktheid of
              beschikbaarheid met betrekking tot de website of de informatie,
              producten, diensten of gerelateerde grafieken op de website voor welk doel dan ook. Elk vertrouwen dat je stelt in dergelijke informatie is
              daarom strikt op eigen risico.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Raimonvibe kan niet aansprakelijk worden gesteld voor enig verlies of schade,
              inclusief maar niet beperkt tot directe of indirecte, gevolg- of
              incidentele schade, voortvloeiend uit of in verband met het gebruik van
              deze website.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Deze website kan links bevatten naar websites van derden die niet
              onder de controle van Raimonvibe vallen. We hebben geen controle over de
              aard, inhoud en beschikbaarheid van die sites, en de
              opname van dergelijke links impliceert niet noodzakelijkerwijs een goedkeuring
              of aanbeveling van de opvattingen die erin worden geuit.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              We streven ernaar om deze website soepel te laten werken. Echter,
              Raimonvibe neemt geen verantwoordelijkheid voor, en is niet aansprakelijk
              voor, de tijdelijke onbeschikbaarheid van de website als gevolg van technische
              problemen buiten onze controle.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0">Contacteer ons: </span>
          </p>
          <p className="c1 c2">
            <span className="c0">E-mail: info@raimonvibe.com</span>
          </p>
          <p className="c1">
            <span className="c0">Website: https://www.raimonvibe.nl</span>
          </p>
          <p className="c1">
            <span className="c0">
              Door onze website te gebruiken, accepteer je onze gebruiksvoorwaarden en privacybeleid. De informatie op deze website is bedoeld voor algemene
              informatiedoeleinden en is niet bedoeld als juridisch advies. We garanderen
              niet de nauwkeurigheid, volledigheid of betrouwbaarheid van enige
              informatie op deze website en zijn niet aansprakelijk voor eventuele fouten
              of weglatingen.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Copyright © {currentYear} raimonvibe. Alle rechten voorbehouden.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <div className="spacing3"></div>
          <div className="spacing3"></div>
        </div>

        <div className="spacing"></div>
      </main>
    </div>
  );
}

// Links naar sociale media met FontAwesome-pictogrammen
// Copyright melding met dynamisch jaar
// Knop om naar de bovenkant van de pagina te scrollen
export default JuridischeKennisgeving;
