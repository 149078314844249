import React from "react";
import pre_programming from "../images/Pre-Programming.webp";
import html_css from "../images/HTML_CSS.webp";
import javascript1 from "../images/JavaScript1.webp";
import javascript2 from "../images/JavaScript2.webp";
import webdevelopment from "../images/WebDevelopment.webp";
import python from "../images/Python.webp";
import python_tableau from "../images/Python_Tableau.webp";
import flutter from "../images/Flutter.webp";
import java1 from "../images/Java1.webp";
import dsa from "../images/dsa.webp";
import assertive from "../images/assertive.webp";
import ai from "../images/ai.webp";
import criminology from "../images/criminology.webp";
import tensorflow from "../images/tensorflow.webp";
import git from "../images/git.webp";
import django from "../images/django-1.webp";
import cSharp from "../images/CSharp.webp";
import CSharpIntermediate from "../images/CSharpIntermediate.webp";
import csharpadvanced from "../images/csharpadvanced.webp";
import CSharpUnitTesting from "../images/CSharpUnitTesting.webp";



function Certificaten() {
  return (
    <div>
      <main>
        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={pre_programming}
            alt="Mijn Pre-Programming certificaat."
          />
          <article>
            <div>
              <h1 className="exlusive-text">Pre-Programming</h1>
              <p>
                In deze cursus leerde ik alles wat nodig is om te beginnen met
                programmeren, inclusief de geschiedenis van technologie-gerelateerde onderwerpen.
              </p>
              <a
                href="https://www.udemy.com/course/pre-programming-everything-you-need-to-know-before-you-code/"
                target="_blank"
                rel="noreferrer"
                className="Social align"
              >
                Pre-Programming
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={html_css}
            alt="Mijn HTML & CSS certificaat"
          />
          <article>
            <div>
              <h1 className="exlusive-text">HTML5 & CSS3</h1>
              <p>
                In deze cursusserie leerde ik de opmaaktaal HTML5 en
                de stijltalen CSS3, die de basis vormen voor het bouwen van een
                website.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/the-ultimate-html-css"
              >
                HTML5 & CSS3
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={javascript1}
            alt="Mijn JavaScript 1 certificaat"
          />
          <article>
            <div>
              <h1 className="exlusive-text">JavaScript 1</h1>
              <p>
                In JavaScript Deel 1 leerde ik de basisprincipes van programmeren. Deze
                cursus leert de basisprincipes die in elke
                programmeertaal voorkomen.
              </p>
              <a
                href="https://codewithmosh.com/p/javascript-basics-for-beginners"
                target="_blank"
                rel="noreferrer"
                className="Social align"
              >
                JavaScript 1
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={javascript2}
            alt="Mijn JavaScript 2 certificaat"
          />
          <article>
            <div>
              <h1>JavaScript 2</h1>
              <p>
                In JavaScript Deel 2 leerde ik meer geavanceerde programmeerconcepten. De cursusinhoud omvat Objectgeoriënteerd programmeren.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/object-oriented-programming-in-javascript"
              >
                JavaScript 2
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={webdevelopment}
            alt="Mijn Web Development certificaat"
          />
          <article>
            <div>
              <h1 className="exlusive-text">Web Development</h1>
              <p>
                In deze complete Web Development bootcamp leerde ik hoe ik een website kan programmeren van de grond af aan tot meer diepgaande onderwerpen.
                Onderwerpen die aan bod komen zijn het ontwikkelen van dynamische websites met een
                database en authenticatiefuncties.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/the-complete-web-development-bootcamp/"
              >
                Web Development
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={python}
            alt="Mijn Python certificaat"
          />
          <article>
            <div>
              <h1>Python Mastery</h1>
              <p>
                Deze cursus biedt een volledig overzicht van de toepassingen
                die met de programmeertaal Python kunnen worden gemaakt.
                Fundamentele aspecten van programmeren, webontwikkeling en machine
                learning worden allemaal behandeld in deze cursus.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/python-programming-course-beginners"
              >
                Python Mastery
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={python_tableau}
            alt="Mijn Python & Tableau certificaat"
          />
          <article>
            <div>
              <h1>Python & Tableau</h1>
              <p>
                Data-analyse en datavisualisatie zijn onderwerpen die in deze
                cursus aan bod komen. Hier leer je hoe je data kunt opschonen, verwerken en creëren.
                Deze data worden vervolgens gepresenteerd in een overzichtelijk document met behulp van kleurrijke tabellen en grafieken.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/python-and-tableau-the-complete-data-analytics-bootcamp/"
              >
                Python & Tableau
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={flutter}
            alt="Mijn Flutter certificaat"
          />
          <article>
            <div>
              <h1>Flutter</h1>
              <p>
                Flutter is een framework van Google voor mobiele app-ontwikkeling
                voor zowel iOS als Android. In deze cursus leerde ik hoe ik
                mobiele apps kan ontwikkelen met behulp van de programmeertaal Dart.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/flutter-bootcamp-with-dart/"
              >
                Flutter
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={java1}
            alt="Mijn Java 1 certificaat"
          />
          <article>
            <div>
              <h1>Java 1</h1>
              <p>
                In Java 1 leerde ik de basisprincipes van programmeren in Java. Het is
                krachtig, geschikt voor het ontwikkelen van grote applicaties en draait in
                een virtuele machine.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/ultimate-java-part-1"
              >
                Java 1
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={dsa}
            alt="Mijn certificaat in Data Structures & Algorithms"
          />
          <article>
            <div>
              <h1>DSA</h1>
              <p>
                Datastructuren zijn manieren om data te organiseren en op te slaan, terwijl
                algoritmen sets van instructies zijn die worden gebruikt om problemen op te lossen of
                taken uit te voeren. Begrip van beide is cruciaal voor het ontwerpen van
                efficiënte softwareoplossingen.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/js-algorithms-and-data-structures-masterclass/"
              >
                DSA
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={assertive}
            alt="Mijn certificaat in Assertieve Communicatie"
          ></img>
          <article>
            <div>
              <h1>AC Masterclass</h1>
              <p>
                De Assertive Communication Skills Masterclass biedt
                individuen technieken om assertieve communicatie te verbeteren,
                inclusief conflictoplossing en effectief luisteren, met als doel
                het verbeteren van communicatiestijlen, zelfbewustzijn en non-verbale
                communicatie.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/assertive-communication-skills-masterclass/"
              >
                AC Masterclass
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={ai}
            alt="Mijn certificaat in Kunstmatige Intelligentie"
          />
          <article>
            <div>
              <h1>Kunstmatige Intelligentie</h1>
              <p>
                In de cursus "Artificial Intelligence A-Z" verdiep ik me in
                de wereld van AI met Python, zelfs zonder eerdere programmeerervaring. Met
                downloadbare codetemplates en intuïtieve tutorials leer ik hoe ik zelflerende AI-systemen kan bouwen.
                Verder richt ik me op real-world toepassingen en krijg ik ondersteuning van ervaren Data Scientists.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/artificial-intelligence-az/"
              >
                Kunstmatige Intelligentie
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={git}
            alt="Mijn certificaat in Git Versiebeheer"
          />
          <article>
            <div>
              <h1>Git</h1>
              <p>
                In mijn Git-cursus heb ik diepgaande kennis en vaardigheden opgedaan
                in versiebeheer, een essentieel aspect voor elke ontwikkelaar. Ik
                leerde hoe ik efficiënt wijzigingen in code kan bijhouden, samenwerken met
                teams, en projecten op een georganiseerde manier kan beheren. Door
                praktische oefeningen heb ik ervaring opgedaan in het opzetten van
                repositories, maken van commits, samenvoegen van branches en oplossen van
                conflicten. Deze cursus heeft me niet alleen technische vaardigheden bijgebracht
                maar ook het belang van goede samenwerking en communicatie in softwareontwikkelingsprojecten benadrukt. Mijn vermogen om versiebeheer als een hulpmiddel voor effectieve samenwerking en projectbeheer te gebruiken is aanzienlijk verbeterd.
              </p>
              <a
                className="Social"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/the-ultimate-git-course"
              >
                Git
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={criminology}
            alt="Mijn certificaat in Criminologie"
          />
          <article>
            <div>
              <h1>Criminologie</h1>
              <p>
                In mijn criminologiecursus heb ik psychologische theorieën onderzocht
                over waarom mensen misdaden plegen, waarbij ik keek naar factoren zoals
                sociale invloeden en persoonlijkheidskenmerken. Ik leerde ook over
                de aanpak van het strafrechtsysteem met betrekking tot rehabilitatie en
                straf. Deze studie heeft me in staat gesteld om crimineel gedrag te analyseren vanuit een psychologisch perspectief, rekening houdend met onderliggende motieven en maatschappelijke invloeden.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/crime-psychology/"
              >
                Criminologie
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={tensorflow}
            alt="Mijn certificaat in TensorFlow"
          />
          <article>
            <div>
              <h1>TensorFlow</h1>
              <p>
                Na het behalen van het TensorFlow Developer Certificaat heb ik
                uitgebreide kennis opgedaan van de fundamenten van machine
                learning en deep learning. Ik leerde hoe ik TensorFlow en
                Keras kan gebruiken om complexe machine learning-modellen te ontwikkelen, te trainen en in te zetten. Deze cursus heeft me uitgerust met de vaardigheden om
                neurale netwerken te bouwen voor beeld- en tekstherkenning, tijdreeksanalyse en meer, met behulp van de nieuwste technieken in kunstmatige intelligentie.
              </p>
              <a
                className="Social"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/tensorflow-developer-certificate-machine-learning-zero-to-mastery/"
              >
                TensorFlow
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={django}
            alt="Mijn certificaat in Django Backend Development deel 1"
          />
          <article>
            <div>
              <h1>Django deel 1</h1>
              <p>
                In het eerste deel van de "Ultimate Django Series" verdiep ik me
                in de essentie van webontwikkeling met Python en Django,
                waarbij ik leer hoe ik MySQL moet opzetten, databases moet beheren met de migraties van Django, en Django-modellen moet gebruiken voor database-interacties. De
                cursus biedt praktische oefeningen en inzichten in het gebruik van
                Django's ORM en Admin-interface, waardoor ik vaardigheden opdoe voor
                probleemoplossing en het toepassen van best practices in webontwikkeling.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/the-ultimate-django-series"
              >
                Django deel 1
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={cSharp}
            alt="Mijn certificaat in C# Basis"
          />
          <article>
            <div>
              <h1>C#</h1>
              <p>
                Na het afronden van de cursus "C# Basics for Beginners" bij Code with Mosh heb ik uitgebreide kennis opgedaan van programmeren met C# en het .NET Framework.
                Ik leerde over primitieve en referentietypen, operatoren, besturingsstroomverklaringen en het werken met data, tekst en bestanden. Deze cursus heeft me uitgerust met de
                vaardigheden om C#-applicaties te debuggen en te ontwikkelen door middel van hands-on oefeningen en praktische voorbeelden. Het biedt een solide basis voor een carrière in web-, mobiele of game-ontwikkeling.
              </p>
              <a
                className="Social"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/csharp-basics-for-beginners"
              >
                C#
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={CSharpIntermediate}
            alt="Mijn certificaat in C# Intermediate"
          />
          <article>
            <div>
              <h1>C# Intermediate</h1>
              <p>
              C# is een programmeertaal ontwikkeld door Microsoft voor het .NET-platform. In deze cursus leerde ik objectgeoriënteerd programmeren, inclusief classes, inheritance, en polymorphism.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://members.codewithmosh.com/p/object-oriented-programming-in-csharp"
              >
                CSharpIntermediate
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={csharpadvanced}
            alt="My certificate in C# Basics"
          />
          <article>
            <div>
            <h1>C# Geavanceerd</h1>
            <p>
De cursus "C# Geavanceerd" van Mosh Hamedani verdiept zich in complexe onderwerpen zoals generics, delegates, events, lambda-expressies, extensiemethoden, LINQ, async/await en dynamische typen om de efficiëntie en flexibiliteit van codering te verbeteren. Deze cursus is perfect voor degenen die hun kennis van C# willen verdiepen, zich willen voorbereiden op codeerinterviews of geavanceerde functies willen beheersen voor professionele ontwikkeling.
            </p>
            <a
              className="Social"
              target="_blank"
              rel="noreferrer"
              href="https://codewithmosh.com/p/csharp-advanced"
            >
              C# Geavanceerd
            </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={CSharpUnitTesting}
            alt="Mijn certificaat in C# Unit Testing"
          />
          <article>
            <div>
              <h1>C# Unit Testing</h1>
              <p>
                Door gebruik te maken van TestNinja in deze cursus, heb ik kritische technieken voor unit testing in C# geleerd, waaronder Test-Driven Development (TDD), dependency injection en mocking frameworks. Deze training heeft mijn vermogen verbeterd om schone, onderhoudbare tests te schrijven en legacy-code te refactoren voor betere testbaarheid.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/unit-testing-for-csharp-developers"
              >
                C# Unit Testing
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

      </main>

      <div className="spacing"></div>
    </div>
  );
}
// Links naar sociale media met FontAwesome-pictogrammen
// Copyright melding met dynamisch jaar
// Knop om naar de bovenkant van de pagina te scrollen

export default Certificaten;
