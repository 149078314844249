import React from "react";
import blog from "../images/blog.webp";
import youtube from "../images/youtube.webp";
import developer from "../images/developer.webp";

function Diensten() {
  return (
    <div>
      <main>
        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={developer}
            alt="Illustratie van een ontwikkelaar."
          ></img>
          <article>
            <div>
              <h1>Software Engineering Oplossingen</h1>
              <p>
                Als ervaren Software Engineer creëer ik eigentijdse,
                gebruiksvriendelijke websites met behulp van gevestigde
                technieken. Ik bied ook diensten voor code-documentatie aan,
                zorgend voor duidelijkheid en het vergemakkelijken van het
                begrip voor aspirant-ontwikkelaars. Met een actieve aanwezigheid
                op Upwork & Fiverr, ben ik toegewijd aan het leveren van
                hoogwaardig werk met precisie en aandacht voor detail.
              </p>
              <div className="spacing"></div>
              <span>
                <p>Bekijk mijn Freelance profiel op:</p>
                <strong>
                  <a
                    className="Social"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.upwork.com/freelancers/~01db073e93aefd8fac"
                  >
                    Upwork
                  </a>
                </strong>
                <br></br>
                <div className="spacing"></div>
                <strong>
                  <a
                    className="Social"
                    target="_blank"
                    rel="noreferrer"
                    href="https://nl.fiverr.com/raimonvibe"
                  >
                    Fiverr
                  </a>
                </strong>
              </span>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>


        <div className="middle-container2">
          <img
            className="common-properties common-properties-left"
            src={blog}
            alt="Illustratie van een blogomgeving."
          ></img>
          <article>
            <div>
              <h1>Blogberichten over mijn Studies</h1>
              <p>
                Op Medium deel ik diepgaande artikelen over mijn ervaringen en
                leerprocessen in online codeerbootcamps, inclusief
                projectvoorbeelden en scripts. Daarnaast schrijf ik
                gepassioneerd over 3D-printen, waarbij ik mijn unieke
                ontwerpproces, stappen voor publicatie en mijn motivatie voor
                deze innovatieve technologie belicht. Als contentcreator op
                YouTube deel ik ook mijn ervaringen en kennis op een
                toegankelijke manier. Dit is slechts een glimp van de diversiteit
                op mijn Medium-blog, waar ik je uitnodig om meer te ontdekken
                over mijn professionele en creatieve reis in de spannende wereld
                van technologie en ontwerp.
              </p>
              <p>Bekijk mijn blogberichten op:</p>
              <strong>
                <a
                  className="Social left-align"
                  target="_blank"
                  rel="noreferrer"
                  href="https://medium.com/@raimonvibe"
                >
                  Medium
                </a>
              </strong>
              <div className="spacing"></div>
            </div>
          </article>
        </div>

        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={youtube}
            alt="Illustratie van een videocreatieomgeving."
          ></img>
          <article>
            <div>
              <h1>YouTube Creator</h1>
              <p>
                Mijn YouTube-kanaal biedt niet alleen instructievideo's over
                het maken van 3D-modellen, maar verkent ook de fascinerende
                wereld van AI-technologie en de technische aspecten van het
                starten van een online bedrijf. Ik deel inzichten over de
                software die cruciaal is voor mijn bedrijf, waardoor het een
                waardevolle bron van kennis is voor iedereen die geïnteresseerd
                is in technologie en online ondernemerschap.
              </p>
              <div className="spacing"></div>
              <span>
                <p>Bekijk mijn YouTube-kanaal op:</p>
                <strong>
                  <a
                    className="Social"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCDGDNuYb2b2Ets9CYCNVbuA/videos"
                  >
                    YouTube
                  </a>
                </strong>
              </span>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}

export default Diensten;
