import React from "react";
import cat from "../images/Cat-v3-final.webp";
import horse from "../images/horse.webp";
import female from "../images/female-v4.webp";
import male from "../images/male.webp";
import buddha from "../images/buddha.webp";
import owl_bowl from "../images/owl-bowl.webp";
import alien from "../images/alien.webp";
import butterfly from "../images/butterfly.webp";
import roses from "../images/roses.webp";
import unicorn from "../images/unicorn.webp";
import cat2 from "../images/cat-v6-final.webp";
import deer from "../images/deer.webp";
import monkeys from "../images/monkeys.webp";
import earrings from "../images/earrings.webp";


// import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles
//
// AOS.init();

const current = new Date();
const date = `${current.getFullYear()}`;

//Get the button:
let mybutton = document.getElementsByClassName("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  mybutton.style = {
    display:
      document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
        ? "block"
        : "none",
  };
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

function DrieDCollectie() {
  return (
    <div>
      <main>

      <div className="spacing"></div>
      <div className="middle-container">
        <img
          className="common-properties common-properties-left"
          src={earrings}
          alt="Schitterende oorbel in sneeuwvlok vorm"
        ></img>
        <article>
          <div>
            <h1 className="exlusive-text">
              Gepersonaliseerde 3D Oorbellen op Maat
            </h1>
            <p>
              Heb je interesse in unieke, op maat gemaakte 3D-oorbellen? Ik
              specialiseer me in het ontwerpen van op maat gemaakte
              3D-modellen van oorbellen die perfect bij jouw stijl passen. Als
              je een persoonlijk en uniek ontwerp wilt laten maken, sta ik
              klaar om jouw ideeën tot leven te brengen. Aarzel niet om
              contact op te nemen voor een ontwerp op maat. Voor meer
              informatie of om contact op te nemen, bezoek alstublieft mijn{" "}
              <a className="custom-link" href="/Contact">
                contactpagina
              </a>
              , waar je mijn e-mailadres vindt.
            </p>
            <div className="spacing3"></div>
          </div>
        </article>
      </div>
        <div className="spacing"></div>
        <div className="middle-container">
          <br />
          <h3 className="sellfy-text">Webshop:</h3>
          <a
            className="Social buy-spacing"
            target="_blank"
            rel="noreferrer"
            href="https://zaap.bio/raimonvibe"
          >
            3D
          </a>
          <div className="spacing"></div>
          <img
            className="model-left"
            src={monkeys}
            alt="Een onderzetter met een aap."
          />
          <article>
            <div>
              <h1 className="exclusive-text">🐵 Onderzetter</h1>
              <p>
                Een prachtige onderzetter set met een print van een aap. De
                download bevat twee bestanden.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://zaap.bio/raimonvibe/3d-model-monkey-drinkcoaster-"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-right"
            src={deer}
            alt="Een grappige kerst hert ornament!"
          />
          <article>
            <div>
              <h1 className="exclusive-text">☃️ Ornament</h1>
              <p>
                Een kerst hert ornament. Ideaal voor kerst ☃️. Geef je
                kerstboom een vrolijke uitstraling met deze prachtige
                versiering!
              </p>
              <a
                className="sellfy-buy-button Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://zaap.bio/raimonvibe/3d-model-christas-deer-ornament-"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-left"
            src={cat2}
            alt="Een prachtige 3D model oorbel in de vorm van een kat."
          />
          <article>
            <div>
              <h1 className="exclusive-text">😺 Oorbellen</h1>
              <p>
                Een unieke oorbel in de vorm van een kat. De kat heeft een mooie
                lange staart. Perfect als cadeau voor jezelf of iemand speciaal.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/jewelry/cat-earrings-v6-raimonvibe"
                data-text="Koop nu"
              >
                €0,50
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-right"
            src={unicorn}
            alt="Een eenhoorn sleutelhanger."
          />
          <article>
            <div>
              <h1 className="exclusive-text">🦄 Sleutelhanger</h1>
              <p>
                Voeg een vleugje magie toe aan je sleutels met deze schattige
                3D-geprinte eenhoorn sleutelhanger! 🦄 Het perfecte accessoire
                voor eenhoornliefhebbers.
              </p>
              <a
                className="sellfy-buy-button Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/home/unicorn-keychain-raimonvibe"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-left"
            src={roses}
            alt="Een set van rozen oorbellen."
          />
          <article>
            <div>
              <h1 className="exclusive-text">🌹 Oorbellen</h1>
              <p>
                Voeg een vleugje natuur geïnspireerde elegantie toe aan je stijl
                met deze prachtige 3D-geprinte rozen oorbellen. 🌹 Het ontwerp
                bevat twee verschillende rozenpatronen.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/jewelry/roses-earrings"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-right"
            src={butterfly}
            alt="Dit is een oorbel in de vorm van een vlinder."
          />
          <article>
            <div>
              <h1 className="exclusive-text">🦋 Oorbel</h1>
              <p>
                Voeg een speciale touch toe aan je look met deze delicate
                3D-geprinte vlinder oorbellen. 🦋 Het complexe ontwerp
                vangt de elegantie van een vlinder in vlucht, perfect voor
                iedereen die houdt van natuur geïnspireerde sieraden.
              </p>
              <a
                className="sellfy-buy-button Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/jewelry/butterfly-earrings-raimonvibe"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-left"
            src={alien}
            alt="Dit is een sleutelhanger in de vorm van een alien."
          />
          <article>
            <div>
              <h1>👽 Sleutelhanger</h1>
              <p>
                Dit is een sleutelhanger in de vorm van een alien. Print het in
                je favoriete kleur of materiaal.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/home/alien-keychain-raimonvibe"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-right"
            src={owl_bowl}
            alt="Kom met een uilenpatroon aan de onderkant."
          />
          <article>
            <div>
              <h1>🦉 Kom</h1>
              <p>
                Deze kom is trendy en handig tegelijk. Gebruik deze kom voor je
                sleutels, portemonnee, kantoorbenodigdheden, enz. De onderkant
                van de kom heeft een uilenpatroon. Hou de kom voor jezelf of
                geef het aan iemand (speciaal). De kom heeft ook een voronoï
                patroon.
              </p>
              <a
                className="sellfy-buy-button Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/home/tray-owl-voronoi"
                data-text="Koop nu"
              >
                €0,50
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-left"
            src={buddha}
            alt="Inspirerende Boeddha onderzetter."
          />
          <article>
            <div>
              <h1>Boeddha Onderzetter</h1>
              <p>
                Decoreer je huis met deze inspirerende Boeddha onderzetter.
                Ideaal als cadeau voor je geliefde(n) of voor jezelf.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/home/buddha-coaster"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-right"
            src={male}
            alt="Prachtige mannelijke torso met voronoï patroon."
          />
          <article>
            <div>
              <h1>Mannelijke Torso</h1>
              <p>
                Prachtige mannelijke torso met voronoï patroon. Een stijlvolle
                decoratie voor je boekenkast. Perfect als cadeau voor iemand
                speciaal of voor jezelf.
              </p>
              <a
                className="sellfy-buy-button Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://zaap.bio/raimonvibe/3d-model-male-torso-voronoi-pattern-"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-left"
            src={female}
            alt="Prachtige vrouwelijke torso met voronoï patroon."
          />
          <article>
            <div>
              <h1>Vrouwelijke Torso</h1>
              <p>
                Prachtige vrouwelijke torso met voronoï patroon. Een elegante
                decoratie voor op je plank. Perfect als cadeau voor iemand
                speciaal of voor jezelf.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://zaap.bio/raimonvibe/3d-model-elegant-female-torso-with-voronoi-pattern"
                data-text="Koop nu"
              >
                €1,-
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-right"
            src={horse}
            alt="Een prachtige 3D-model sleutelhanger in de vorm van een paard."
          />
          <article>
            <div>
              <h1>🐎 Sleutelhanger</h1>
              <p>
                Een unieke sleutelhanger in de vorm van een paard met geometrische
                vormen. Perfect als cadeau voor iemand speciaal of voor jezelf.
              </p>
              <a
                className="sellfy-buy-button Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/home/geometrical-horse-keychain"
                data-text="Koop nu"
              >
                Gratis!
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="model-left"
            src={cat}
            alt="Een prachtige 3D-model oorbel in de vorm van een kat."
          />
          <article>
            <div>
              <h1>🐈‍⬛ Oorbellen (v3)</h1>
              <p>
                Een unieke oorbel in de vorm van een kat. Perfect als cadeau voor
                iemand speciaal of voor jezelf.
              </p>
              <a
                className="sellfy-buy-button Social align"
                target="_blank"
                rel="noreferrer"
                href="https://cults3d.com/en/3d-model/jewelry/cat-v3-earrings"
                data-text="Koop nu"
              >
                €0,50
              </a>
              <div className="spacing3"></div>
              <div className="spacing3"></div>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>
    </div>
  );
}

export default DrieDCollectie;
