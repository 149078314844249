import React from "react";
import breathe from "../images/breathe.webp";
import welcome from "../images/welcome.webp";

function Welkom() {
  return (
    <div>
      <main>
        <div className="spacing"></div>
        <div className="video-container">
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/PhokNIST824"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          </div>
        </div>
        <div className="spacing"></div>

        <div className="header">
          <img
            className="common-properties common-properties-left"
            src={welcome}
            alt="Illustratie van technologie"
          />
          <article>
            <div>
              <h1>Over raimonvibe</h1>
              <p>
                Raimonvibe, geleid door Raimon, is een toonaangevend onderzoeksbedrijf binnen het technologieveld. Met meer dan twee jaar ervaring in programmeren heeft ons bedrijf een breed scala aan technologieën verkend en onderzocht om de grenzen van digitale innovatie te verleggen. Onze onderzoeksexpertise omvat geavanceerd programmeren in Python, JavaScript, HTML en CSS, en Flutter Dart, waarmee we zowel frontend- als backendoplossingen bestuderen voor naadloze en efficiënte integratie.
              </p>
              <p>
                Onze onderzoeksprojecten maken gebruik van krachtige frameworks en technologieën zoals React, Django en Node.js, in combinatie met databasesystemen zoals MongoDB en Mongoose. We zijn ook bedreven in Data Science en Data Visualisatie, waarbij we deze vaardigheden gebruiken om complexe datasets om te zetten in duidelijke, inzichtelijke visuele rapporten. Met onze grondige kennis van AI-programmering met TensorFlow kunnen we concepten voor op maat gemaakte AI-oplossingen onderzoeken en ontwikkelen die intelligente, op data gebaseerde besluitvorming ondersteunen.
              </p>
            </div>
          </article>
        </div>
        <div className="spacing"></div>

        <div className="video-container">
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/bSq3YELK5ww"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
            ></iframe>
          </div>
        </div>
        <div className="spacing"></div>

        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={breathe}
            alt="Illustratie van woonkamer"
          />
          <article>
            <div>
              <h1>Onze Technologische Impact</h1>
              <p>
                Bij raimonvibe richten we ons niet alleen op het ontwikkelen van technologie, maar ook op het begrijpen en innoveren ervan. Onze onderzoeksinitiatieven omvatten het gebruik van moderne servertechnologieën, waaronder VPS (Virtual Private Servers), om robuuste, schaalbare applicaties te hosten die wereldwijd toegankelijk zijn. Onze vaardigheden met JQuery en het implementeren van real-time webapplicaties met Express stellen ons in staat om responsieve en interactieve websites te bouwen die de gebruikerservaring verbeteren.
              </p>
              <p>
                We zijn trots op onze aanpak van continue verbetering en leren, waarbij elk project wordt gezien als een kans om onze vaardigheden te verdiepen en nieuwe technologieën te verkennen. Ons doel is altijd aan de voorhoede van technologische vooruitgang te staan, en om onze bevindingen en ontwikkelingen te delen met de bredere gemeenschap, en zo bij te dragen aan de evolutie van de technologie-industrie.
              </p>
            </div>
          </article>
        </div>
        <div className="spacing"></div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}

export default Welkom;
