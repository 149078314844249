import React from "react";
import raimon from "../images/raimon100.webp"; // Importeren van persoonlijke afbeelding

function OverMij() {
  return (
    <div>
      <main>
        <div className="header">
          <div className="spacing2"></div>
          <img
            className="common-properties common-properties-left2"
            src={raimon}
            alt="Een selfie"
          ></img>
          <article>
            <div>
              <h1>Hallo, ik ben Raimon</h1>
              <p>
Tijdens mijn digitale reis combineer ik technologie met creativiteit, en creëer unieke ontwerpen die de grenzen van de mogelijkheden verleggen. Deze positieve instelling helpt me mijn ware potentieel te realiseren, ongehinderd door de verwachtingen van anderen. Mijn uitdagingen hebben mijn persoonlijke en professionele groei niet tegengehouden. Ik geloof sterk dat iedereen, ondanks uitdagingen, hun dromen kan verwezenlijken met vastberadenheid en ondersteuning. Ik waardeer je begrip en interesse in mijn verhaal enorm. Ik hoop dat het je inspireert om obstakels te overwinnen en het volledige potentieel in je eigen reis te ontdekken.</p>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
      <div className="spacing"></div>

    </div>
  );
}
// Links naar sociale media met FontAwesome-pictogrammen
// Copyright melding met dynamisch jaar
// Knop om naar de bovenkant van de pagina te scrollen

export default OverMij;
