import React from "react";
import Welkom from "./Welkom"; // Welcome
import OverMij from "./OverMij"; // About me
import Diensten from "./Diensten"; // Services
import Cliënten from "./Cliënten"; // Clients
import Contact from "./Contact"; // Contact
import Certificaten from "./Certificaten"; // Certificates
import Webwinkel from "./Webwinkel"; // Webwinkel
import Footer from "./Footer";
import NavbarHome from "./NavbarHome";
import Privacybeleid from "./Privacybeleid"; // Privacy Policy
import JuridischeKennisgeving from "./JuridischeKennisgeving"; // Legal Notice
import CurriculumVitae from "./CurriculumVitae";
import Perspectieven from "./Perspectieven"; // Importing the Perspectieven component
import DrieDCollectie from "./DrieDCollectie"; // Importing the Perspectieven component
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <NavbarHome />
        <Routes basename={process.env.PUBLIC_URL}>
          <Route path="/" element={<Welkom />} />
          <Route path="/Diensten" element={<Diensten />} />
          <Route path="/OverMij" element={<OverMij />} />
          <Route path="/Cliënten" element={<Cliënten />} />
          <Route path="/Certificaten" element={<Certificaten />} />
          <Route path="/CurriculumVitae" element={<CurriculumVitae />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Webwinkel" element={<Webwinkel />} />
          <Route path="/Privacybeleid" element={<Privacybeleid />} />
          <Route path="/JuridischeKennisgeving" element={<JuridischeKennisgeving />} />
          <Route path="/Perspectieven" element={<Perspectieven />} /> {/* Added the Perspectieven route */}
          <Route path="/DrieDCollectie" element={<DrieDCollectie />} /> {/* Added the Perspectieven route */}      
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
