import React from "react";
import collabs from "../images/gosolo.webp";
import techbullion from "../images/techbullion.webp";

function Cliënten() {
  return (
    <div>
      <main>
         <div className="middle-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.collabs.io/mag/raimonvibe/"
          >
            <img
              className="common-properties common-properties-left"
              src={collabs}
              alt="Mijn bedrijf in het online magazine Collabs"
            />
          </a>
          <article>
            <div>
              <h1>Geïnterviewd door Collabs</h1>
              <p>
                Ik ben geïnterviewd voor het online magazine Collabs. Ik was erg
                verrast toen ik werd gevraagd om een verhaal te schrijven over mijn bedrijf.
                Lees mijn verhaal door op de afbeelding te klikken.
              </p>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="middle-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://techbullion.com/is-software-development-a-dead-end-job-after-age-35/"
          >
            <img
              className="common-properties common-properties-right"
              src={techbullion}
              alt="Mijn bedrijf in het online magazine TechBullion"
            />
          </a>
          <article>
            <div>
              <h1>Fintech Magazine TechBullion</h1>
              <p>
                Ik ben vereerd om te delen dat ik onlangs ben verschenen in Fintech
                Magazine met mijn inzichten, gepubliceerd door TechBullion. Dit is een
                belangrijke mijlpaal in mijn reis en ik ben dankbaar voor de
                gelegenheid om mijn kennis te delen. Lees mijn verhaal door op
                de afbeelding te klikken.
              </p>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}

export default Cliënten;
