import React from "react";

function Privacybeleid() {
  return (
    <div>
      <main>
        <div className="body2">
          <div className="spacing3"></div>
          <div className="spacing3"></div>

          <p className="c1">
            <span className="c0" id="top">
              Het bedrijf, gevestigd aan Timpaan 1-B 1628 MT Hoorn, is
              verantwoordelijk voor de verwerking van persoonsgegevens zoals
              weergegeven in deze privacyverklaring.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Contactgegevens:</span>
          </p>
          <p className="c1">
            <span className="c0">info@raimonvibe.com</span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Persoonsgegevens die we verwerken</span>
          </p>
          <p className="c1">
            <span className="c0">
              Raimonvibe neemt uw privacy serieus en is transparant over de
              persoonsgegevens die we verwerken. Wanneer u onze website bezoekt
              of onze diensten gebruikt, kunnen we de volgende persoonsgegevens
              over u verwerken:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Contactinformatie zoals uw e-mailadres, als u het vrijwillig aan
              ons verstrekt bij het invullen van een contactformulier of bij het
              aanmelden voor onze nieuwsbrief.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Informatie over uw surfgedrag op onze website om onze diensten en
              uw gebruikerservaring te verbeteren. Dit omvat gegevens die zijn
              verzameld via cookies, zoals bezochte pagina's, klikgedrag en de
              duur van uw bezoek.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Doel van en basis voor de verwerking van persoonsgegevens
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              We verwerken uw persoonsgegevens voor specifieke doeleinden en op
              geldige juridische gronden, waaronder:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Om contact met u op te nemen en te reageren op uw verzoeken of
              vragen, als u ons hiervoor toestemming heeft gegeven.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Voor het analyseren van uw gebruik van onze website met als doel
              onze website en diensten te verbeteren. We doen dit op basis van
              ons gerechtvaardigd belang om onze dienstverlening
              voortdurend te optimaliseren.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Om te voldoen aan wettelijke verplichtingen, zoals het bijhouden
              van gegevens voor belastingdoeleinden of het reageren op
              verzoeken van bevoegde autoriteiten.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Delen van persoonsgegevens met derden</span>
          </p>
          <p className="c1">
            <span className="c0">
              Raimonvibe zal uw persoonsgegevens niet verkopen, delen of
              openbaar maken aan derden zonder uw toestemming, tenzij dit
              noodzakelijk is voor de uitvoering van onze overeenkomst met u of
              om te voldoen aan een wettelijke verplichting. In gevallen waarin
              we samenwerken met externe dienstverleners of partners, zorgen we
              ervoor dat er passende overeenkomsten zijn om uw privacy te
              beschermen.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Beveiliging en bewaring van persoonsgegevens</span>
          </p>
          <p className="c1">
            <span className="c0">
              We nemen de bescherming van uw persoonsgegevens serieus en
              implementeren passende technische en organisatorische maatregelen
              om uw gegevens te beschermen tegen onrechtmatige verwerking,
              verlies, misbruik, ongeoorloofde toegang, openbaarmaking,
              wijziging of vernietiging. Uw persoonsgegevens worden niet langer
              bewaard dan strikt noodzakelijk is om de doeleinden te bereiken
              waarvoor uw gegevens worden verzameld.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Bijzondere en/of gevoelige persoonsgegevens die we verwerken
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Onze website en/of dienst is niet bedoeld voor het verzamelen van
              gegevens over websitebezoekers die jonger zijn dan 16 jaar, tenzij
              ze toestemming hebben van hun ouders of voogd. We kunnen echter
              niet controleren of een bezoeker ouder is dan 16 jaar. We raden
              ouders dan ook aan betrokken te zijn bij de online activiteiten
              van hun kinderen om te voorkomen dat er gegevens worden verzameld
              over kinderen zonder ouderlijke toestemming. Als u ervan overtuigd
              bent dat we zonder die toestemming persoonlijke gegevens hebben
              verzameld over een minderjarige, neem dan contact met ons op via
              info@raimonvibe.com, dan verwijderen we deze informatie.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Doel en basis voor de verwerking van persoonsgegevens
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - Het bedrijf verwerkt uw persoonsgegevens voor de volgende
              doeleinden:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - Het bedrijf analyseert uw gedrag op de website om de website te
              verbeteren en het assortiment van producten en diensten af te
              stemmen op uw voorkeuren.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - Het bedrijf volgt uw surfgedrag op verschillende websites waarop
              we ons assortiment van producten en diensten afstemmen op uw
              behoeften.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Geautomatiseerde besluitvorming</span>
          </p>
          <p className="c1">
            <span className="c0">
              Het bedrijf neemt zakelijke beslissingen op basis van
              geautomatiseerde verwerking van zaken die (aanzienlijke)
              gevolgen kunnen hebben voor mensen. Dit zijn beslissingen die
              worden genomen door computerprogramma's of systemen, zonder
              tussenkomst van een persoon (bijvoorbeeld een medewerker van het
              bedrijf). Het bedrijf maakt gebruik van de volgende
              computerprogramma's of systemen:
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a className="c5" href="https://heapanalytics.com/">
                https://heapanalytics.com/
              </a>
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a className="c5" href="https://cookie-script.com/">
                https://cookie-script.com/
              </a>
            </span>
          </p>
          <p className="c1">
            <span className="c0">Cookies of vergelijkbare technieken die we gebruiken</span>
          </p>
          <p className="c1">
            <span className="c0">
              Het bedrijf maakt gebruik van functionele, analytische en
              trackingcookies. Een cookie is een klein tekstbestand dat wordt
              opgeslagen in de browser van uw computer, tablet of smartphone
              wanneer u deze website voor het eerst bezoekt. Het bedrijf
              gebruikt cookies met een puur technische functionaliteit. Deze
              zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld
              uw voorkeursinstellingen worden onthouden. Deze cookies worden ook
              gebruikt om de website goed te laten werken en te optimaliseren.
              Daarnaast plaatsen we cookies die uw surfgedrag bijhouden zodat we
              op maat gemaakte inhoud en advertenties kunnen aanbieden.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Tijdens uw eerste bezoek aan onze website hebben we u al geïnformeerd
              over deze cookies en hebben we uw toestemming gevraagd om ze te
              plaatsen.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              U kunt zich afmelden voor cookies door uw internetbrowser zo in te
              stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook
              alle informatie die eerder is opgeslagen via de instellingen van uw
              browser verwijderen.
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a
                className="c5"
                href="https://veiliginternetten.nl/search/?q=cookies"
              >
                https://veiliginternetten.nl/cookies/
              </a>
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Op deze website worden ook cookies geplaatst door derden. Dit zijn
              bijvoorbeeld adverteerders en/of sociale mediabedrijven. Hieronder
              volgt een overzicht:
            </span>
          </p>
          <p className="c1">
            <span className="c0">Cookie: Heap Analytics.</span>
          </p>
          <p className="c1">
            <span className="c0">Naam: Heap Analytics.</span>
          </p>
          <p className="c1">
            <span className="c0">
              Functie: Analytische cookie die websitebezoeken meet.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Cookie: CookieScript.</span>
          </p>
          <p className="c1">
            <span className="c0">Naam: CookieScript.</span>
          </p>
          <p className="c1">
            <span className="c0">
              Functie: Biedt een pop-upvenster voor cookies.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Bekijk, pas aan of verwijder gegevens</span>
          </p>
          <p className="c1">
            <span className="c0">
              U heeft het recht om uw persoonsgegevens in te zien, te corrigeren
              of te verwijderen. Dit kunt u zelf doen via de persoonlijke
              instellingen van uw account. Daarnaast heeft u het recht om uw
              toestemming voor gegevensverwerking in te trekken of bezwaar te
              maken tegen de verwerking van uw persoonsgegevens door ons bedrijf,
              en heeft u het recht op gegevensoverdraagbaarheid. Dit betekent dat
              u een verzoek bij ons kunt indienen om de persoonsgegevens die we
              van u hebben in een computerbestand naar u of een ander, door u
              genoemde organisatie, te sturen.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Als u gebruik wilt maken van uw recht om bezwaar te maken en/of uw
              recht op gegevensoverdraagbaarheid of als u andere
              vragen/opmerkingen heeft over gegevensverwerking, stuur dan een
              specifiek verzoek naar info@raimonvibe.com.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Om er zeker van te zijn dat het verzoek tot inzage door u is
              gedaan, vragen we u een kopie van uw identiteitsbewijs bij het
              verzoek te voegen. Zorg ervoor dat u uw pasfoto, MRZ (machine
              leesbare zone, de strook met nummers onderaan het paspoort),
              paspoortnummer en Burgerservicenummer (BSN) zwart maakt in deze
              kopie. Dit ter bescherming van uw privacy. Het bedrijf reageert
              zo snel mogelijk, maar binnen vier weken, op uw verzoek.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Het bedrijf wijst er ook op dat u de mogelijkheid heeft om een
              klacht in te dienen bij de nationale toezichthoudende autoriteit,
              de Autoriteit Persoonsgegevens. Dit kan via de volgende link:
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a
                className="c5"
                href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
              >
                https://autoriteitpersoonsgegevens.nl/
              </a>
            </span>
          </p>

          <p className="c1">Hoe we persoonsgegevens beschermen</p>
          <p className="c1">
            <span className="c0">
              Raimonvibe neemt de bescherming van uw gegevens serieus en neemt
              passende maatregelen om misbruik, verlies, onbevoegde toegang,
              ongewenste openbaarmaking en ongeoorloofde wijziging tegen te
              gaan. Als u van mening bent dat uw gegevens niet goed zijn
              beveiligd of er aanwijzingen zijn van misbruik, neem dan contact op
              met onze klantenservice of via info@raimonvibe.com.
            </span>
          </p>
          <div className="spacing3"></div>
          <div className="spacing3"></div>

          <div className="spacing"></div>
        </div>
      </main>
    </div>
  );
}

export default Privacybeleid;
