import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faSitemap, faLaptopCode, faCode, faGlobe } from '@fortawesome/free-solid-svg-icons';

function Perspectieven() {
  return (
    <div>
      <main>
      <div className="middle-container2">
      <article>
        <h1>Mijn Professionele Reis en Perspectieven</h1>
        <p>In de loop van mijn carrière heb ik waardevolle perspectieven opgedaan door in contact te komen met een reeks gerenommeerde bedrijven in verschillende industrieën. Deze mogelijkheden komen voort uit organisaties die waarde hechten aan innovatie, technologie en creatief probleemoplossend vermogen - kwaliteiten die aansluiten bij mijn professionele doelen.</p>

        <h1>Ontdek de Bedrijven Waarmee Ik in Contact Ben Geweest:</h1>

        <h3>
          <FontAwesomeIcon icon={faCartShopping} className="opportunity-icon" /> E-commerce en Cloud-gebaseerde Oplossingen
        </h3>
        <ul>
          <li><a class="opportunity" href="https://commercetools.com/" target="_blank" rel="noopener noreferrer">Commercetools</a></li>
          <li><a class="opportunity" href="https://payever.careers/" target="_blank" rel="noopener noreferrer">PAYEVER</a></li>
        </ul>

        <h3>
          <FontAwesomeIcon icon={faSitemap} className="opportunity-icon" /> IT Consulting en Ontwikkeling
        </h3>
        <ul>
          <li><a class="opportunity" href="https://www.tcs.com/careers" target="_blank" rel="noopener noreferrer">Tata Consultancy Services</a></li>
          <li><a class="opportunity" href="https://careers.cognizant.com/nl/nl" target="_blank" rel="noopener noreferrer">Cognizant</a></li>
          <li><a class="opportunity" href="https://q-logic.nl/" target="_blank" rel="noopener noreferrer">Qlogic</a></li>
        </ul>

        <h3>
          <FontAwesomeIcon icon={faLaptopCode} className="opportunity-icon" /> Cross-Platform en Mobiele Ontwikkeling
        </h3>
        <ul>
          <li><a class="opportunity" href="https://akkodis.com/" target="_blank" rel="noopener noreferrer">Akkodis</a></li>
        </ul>

        <h3>
          <FontAwesomeIcon icon={faCode} className="opportunity-icon" /> Talentontwikkeling en IT-diensten
        </h3>
        <ul>
          <li><a class="opportunity" href="https://vitas.nl/young-talent" target="_blank" rel="noopener noreferrer">VITAS Young Talent</a></li>
          <li><a class="opportunity" href="https://cloudstaff.com/" target="_blank" rel="noopener noreferrer">Cloudstaff</a></li>
        </ul>
        </article>
        </div>

        <div className="middle-container2">
        <article>
        <p>Deze perspectieven hebben mij geholpen om mijn professionele vaardigheden verder te ontwikkelen door inzichten te krijgen in verschillende industrieën. Van e-commerce tot IT-consulting, en van cross-platform ontwikkeling tot talentontwikkeling, deze mogelijkheden hebben mij geleid op een pad van continue leermomenten en vaardigheidsontwikkeling. Elke kans heeft bijgedragen aan het verfijnen van mijn expertise en het aanpassen aan nieuwe uitdagingen in het voortdurend veranderende technologische landschap.</p>

        <p>Ik ben dankbaar voor de mogelijkheden om in contact te komen met bedrijven die mijn capaciteiten en potentieel erkennen. Deze perspectieven hebben niet alleen mijn professionele groei bevorderd, maar hebben ook een gevoel van voldoening en tevredenheid gebracht. Het is inspirerend om samen te werken met organisaties die mijn passie voor technologie en innovatie delen, en dit heeft veel professionele voldoening gebracht. Deze kansen hebben mij in staat gesteld om een zinvolle bijdrage te leveren terwijl ik groei naast toonaangevende bedrijven in de industrie.</p>

        <p>De omgeving die door deze perspectieven is gevormd, is er een van dynamische groei en potentieel. Terwijl ik door deze mogelijkheden navigeerde, kwam ik uitdagingen tegen die mij dwongen om te innoveren en kritisch na te denken. De route die door deze bedrijven is uitgestippeld, heeft mij geleid door diverse sectoren, elk met zijn eigen complexiteit en beloningen. Deze reis heeft een ecosysteem van voortdurende verbetering gecreëerd, waarbij elke nieuwe uitdaging een opstap wordt naar verder succes. De diversiteit in deze mogelijkheden heeft niet alleen mijn vaardigheden verrijkt, maar ook mijn perspectief verbreed, waardoor ik de onderlinge verbondenheid van verschillende vakgebieden kon zien en hoe ze bijdragen aan het grotere geheel van technologie en ontwikkeling.</p>

        <p><em>Houd er rekening mee dat hoewel ik in contact ben geweest met deze bedrijven via verschillende mogelijkheden, deze pagina geen formele goedkeuring of partnerschap impliceert.</em></p>

        <h2>
          <FontAwesomeIcon icon={faGlobe} className="opportunity-icon" /> Een Dynamisch Netwerk
        </h2>
        <p>De functies die me zijn aangeboden, weerspiegelen de diverse reeks vaardigheden en ervaringen die ik aan tafel breng. Ik word voortdurend geïnspireerd door de innovaties in deze industrieën en kijk uit naar verdere kansen om bij te dragen en te groeien.</p>
        </article>
        </div>
      </main>
    </div>
  );
}

export default Perspectieven;
