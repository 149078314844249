import React from "react";
import idea from "../images/idea.webp";
import post_box from "../images/post_box.webp";

function Contact() {
  return (
    <div>
      <main>
        <div className="header">
          <img
            className="common-properties common-properties-left"
            src={post_box}
            alt="Illustratie van een postbus."
          />
          <br />
          <br />
          <article>
            <div>
              <h1>Contact</h1>
              <p>
                Als je vragen hebt over de diensten die ik aanbied, stuur me dan
                een bericht. Ik zal zo snel mogelijk contact met je opnemen.
              </p>
              <div className="spacing3"></div>
              <a className="Social" href="mailto:info@raimonvibe.com">
                Contact
              </a>
            </div>
          </article>
        </div>

        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={idea}
            alt="Illustratie van een gloeilamp die in een lampenkap wordt gedraaid"
          />
          <article>
            <div>
              <h1>Als je ideeën hebt.</h1>
              <p>
                Feedback verzamelen van cliënten is een manier om te laten zien dat ik hun
                mening waardeer. Door mijn cliënten om feedback te vragen, geef ik aan
                dat hun mening belangrijk voor mij is. Ik betrek hen bij het
                vormgeven van mijn bedrijf, zodat ze zich meer verbonden voelen met mijn bedrijf.
                Door naar hun stemmen te luisteren, kan ik een duurzame
                verbinding met hen opbouwen.
              </p>
              <p>"We horen graag van je."</p>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}
// Links naar sociale media met FontAwesome-pictogrammen
// Copyright melding met dynamisch jaar
// Knop om naar de bovenkant van de pagina te scrollen

export default Contact;
