import React from "react";
import { Link } from "react-router-dom"; // Importeren van noodzakelijke componenten van react-router-dom
import cover from "../images/cover.webp"; // Importeren van de cover afbeelding
import Dropdown11 from './Dropdown11'; // Importeren van de Dropdown11 component

function NavbarHome() {
  // Array met objecten voor elke navigatielink
  const navLinks = [
    { to: "/", label: "Welkom" }, // Aangepast van "Home" naar "Welkom"
    { to: "/Diensten", label: "Diensten" },
    { to: "/Perspectieven", label: "Perspectieven" }, // Toegevoegd als 3e element
    { to: "/OverMij", label: "Over Mij" },
    { to: "/Cliënten", label: "Cliënten" }, // Aangepaste label met trema
    { to: "/DrieDCollectie", label: "DrieDCollectie" }, // Aangepaste label met trema
    { to: "/Certificaten", label: "Certificaten" },
    { href: "https://raimonvibe.nl/CurriculumVitae", label: "Curriculum Vitae", target: "_blank" }, // Aangepaste link naar Curriculum Vitae
    { to: "/Contact", label: "Contact" },
    { to: "/Webwinkel", label: "Webwinkel" },

  ];

  return (
    <div>
      <nav className="navbar">
        <Link to="/">
          <img
            className="logo"
            src={cover}
            alt="Logo van mijn bedrijf"
          />
        </Link>
        <Dropdown11 links={navLinks} /> {/* Geef alle navLinks door aan Dropdown11 */}
      </nav>
    </div>
  );
}

export default NavbarHome; // Exporteren van de NavbarHome component
